import { createRouter, createWebHistory } from 'vue-router';

import Welcome from '../views/Welcome.vue';

import Legals from '../views/Legals.vue';

import MyDocuments from '../views/user/MyDocuments.vue';
import JuridicalDocuments from '../views/user/JuridicalDocuments.vue';

import LegalCases from '../views/user/LegalCases.vue';
import MyCases from '../views/user/LegalCases/MyCases.vue';
import AllCases from '../views/user/LegalCases/AllCases.vue';

import { admin } from './admin';
import { office } from './office';
import { account } from './account';
import { legalCase } from './legalCase';
import { subscription } from './subscription';
import { authentication } from './authentication';

const routes = [
  ...admin,
  ...office,
  ...account,
  ...legalCase,
  ...subscription,
  ...authentication,
  // Welcome
  {
    path: '/welcome',
    name: 'Welcome',
    component: Welcome,
    meta: {}
  },
  // User
  {
    path: '/legal-cases',
    name: 'LegalCases',
    component: LegalCases,
    redirect: '/legal-cases/my-cases',
    meta: {
      menu: true,
    },
    children: [
      {
        path: '/legal-cases/my-cases',
        name: 'MyCases',
        component: MyCases,
        meta: {
          footer: true,
        }
      },
      {
        path: '/legal-cases/all-cases',
        name: 'AllCases',
        component: AllCases,
        meta: {
          footer: true,
        }
      }
    ]
  },
  {
    path: '/my-documents',
    name: 'MyDocuments',
    component: MyDocuments,
    meta: {
      menu: true,
      footer: true,
      sidebarLeft: 'search',
      sidebarRight: 'preview'
    }
  },
  {
    path: '/juridical-documents',
    name: 'JuridicalDocuments',
    component: JuridicalDocuments,
    meta: {
      menu: true,
      footer: true,
      sidebarLeft: 'search',
      sidebarRight: 'preview'
    }
  },
  {
    path: '/legals',
    name: 'Legals',
    component: Legals,
    meta: {
      userMenu: true
    }
  },

  // 404 to login
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
