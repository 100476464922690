import Api from '@/services/api';


export const endpoints = {
  // Auth
  "login": "/auth/login",
  "logout": "/auth/logout",
  "register": "/auth/register",
  "sanctum": "/sanctum/csrf-cookie",
  "forgotPassword": "/auth/forgot-password",
  "resetPassword": "/auth/reset-password",
  // User
  "getUser": "/user",
  "updateUser": "/user/update",
  "inviteUser": "/client/invite",
  "deleteUser": "/client/user/{id}",
  "checkRegister": "/register-check",
  "getOnboardingStatus": "/onboarding/status",
  "setOnboardingCasus": "/onboarding/set-casus",
  "setOnboardingDone": "/onboarding/done",
  "getUserCategories": "/user/skill-categories",
  "setUserCategories": "/user/skill-categories",
  "updateUserPicture": "/user/update-picture",
  // Client
  "getClient": "/client",
  "setClientAdmin": "/client/admin",
  "getClientLawyers": "/client/{uuid}/users",
  "setClientMailSystem": "/client/mail-system",
  "getClientSubscription": "/client/subscription",
  "setClientName": "/onboarding/set-client-name",
  "getClientPolicies": "/client/{uuid}/policies",
  "setClientPolicies": "/client/{uuid}/policies",
  // "setClientCategories": "/skillCategories",
  "getCategories": "/skillCategories",
  // Document
  "getDocs": "/documents",
  "searchDocs": "/documents/search",
  "uploadDoc": "/documents/upload-file",
  "deleteDoc": "/documents/delete-file",
  "getDocPrompt": "prompts/openai-labelling",
  "getDoc": "/documents/as-raw-text/{token}",
  "regenerateDoc": "/launch/openai-labelling",
  "downloadDoc": "/documents/download/{token}",
  "getDocsState": "/documents/as-raw-text/ping/",
  "docUpdateLabelling": "/documents/update-labelling",
  // Legal case
  "getLegalCases": "/legal-cases",
  "getLegalCase": "/legal-cases/{token}",
  "closeLegalCase": "/legal-cases/close",
  "openLegalCase": "/legal-cases/open",
  "refuseLegalCase": "/legal-cases/refuse",
  "reassignLegalCase": "/legal-cases/assign",
  "addDocToLegalCase": "/legal-cases/add-document?legalcase_token={legalcase}&document_token={doc}",
  // Subscription
  "subscribe": "/client/subscribe",
  "unsubscribe": "/client/unsubscribe",
  "updatePlan": "/client/subscribe/plan/change",
  "updatePayment": "/client/subscribe/payment/change",
  "getIntent": "/client/payment-intent",
  "getOptions": "/plans",
  // Lawcases
  "getLawcases": "search",
  // Categories
  "getCategoryLawcases": "/skill-categories/{id}/law-cases",
  "getCategoryLawyers": "/skill-categories/{id}/users",
  "getCategorySkills": "/skill-categories/{id}/skills",
  // Search
  "getSearchFields": "/structures/{name}"
}

export const calls = new Api({
  baseURL: process.env.VUE_APP_API_URL + process.env.VUE_APP_API_PATH,
  header: { 'Accept': 'application/json' }
}, endpoints);

export const authCalls = new Api({
  baseURL: process.env.VUE_APP_API_URL,
  header: { 'Accept': 'application/json' }
}, endpoints);

export const adminCalls = new Api({
  baseURL: process.env.VUE_APP_TUA_URL + process.env.VUE_APP_ADMIN_PATH
}, endpoints);
