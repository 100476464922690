import LegalCase from '../views/user/LegalCase.vue';
import LCDemand from '../views/user/LegalCase/Demand.vue';
import LCLitigeantDocuments from '../views/user/LegalCase/LitigeantDocuments.vue';
import LCOfficeDocuments from '../views/user/LegalCase/OfficeDocuments.vue';
import LCJuridicalDocuments from '../views/user/LegalCase/JuridicalDocuments.vue';

export const legalCase = [
  {
    path: '/legal-case/:token',
    name: 'LegalCase',
    component: LegalCase,
    meta: {
      // layout: 'listing'
    },
    // redirect: '/legal-case/:token/litigeant-documents',
    redirect: to => {
      return { name: 'LCDemand', params: { token: to.params.token } }
    },
    children: [
      {
        path: '/legal-case/:token/demand',
        name: 'LCDemand',
        component: LCDemand,
        meta: {
          topbar: 'legalcase',
          back: { name: 'LegalCases' }
        }
      },
      {
        path: '/legal-case/:token/litigeant-documents',
        name: 'LCLitigeantDocuments',
        component: LCLitigeantDocuments,
        meta: {
          topbar: 'legalcase',
          back: { name: 'LegalCases' },
        }
      },
      {
        path: '/legal-case/:token/office-documents',
        name: 'LCOfficeDocuments',
        component: LCOfficeDocuments,
        meta: {
          topbar: 'legalcase',
          back: { name: 'LegalCases' },
          sidebarLeft: 'search',
          sidebarRight: 'preview',
          if: {
            condition: (legalcase) => legalcase.status !== 'new',
            fallbackRoute: 'LCLitigeantDocuments'
          }
        }
      },
      {
        path: '/legal-case/:token/juridical-documents',
        name: 'LCJuridicalDocuments',
        component: LCJuridicalDocuments,
        meta: {
          topbar: 'legalcase',
          back: { name: 'LegalCases' },
          sidebarLeft: 'search',
          sidebarRight: 'preview',
          if: {
            condition: (legalcase) => legalcase.status !== 'new',
            fallbackRoute: 'LCLitigeantDocuments'
          }
        }
      },
      {
        path: '/legal-case/:token/:pathMatch(.*)*',
        redirect: to => {
          return { name: 'LCDemand', params: { token: to.params.token } }
        },
      },
      // {
      //   path: '/legal-case/:token/suggested-documents',
      //   name: 'LCProposedDocuments',
      //   component: LCProposedDocuments,
      //   meta: {
      //     topbar: 'legalCase',
      //     back: 'LegalCases',
      //     sidebarLeft: true,
      //     layout: 'listing',
      //     if: {
      //       condition: (legalcase) => legalcase.status !== 'new',
      //       fallbackRoute: 'LCLitigeantDocuments'
      //     }
      //   }
      // }
    ]
  },
];