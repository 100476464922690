<template>
  <div class="subscription">
    <Header
      :title="$route ? $t('pages.office.subscription.title') : null"
      :actions="loading ? [] : actions"
    />

    <div v-if="loading" class="lawyers-list-loader">
      <Loader />
    </div>

    <template v-else>
      <div class="subscription-stats box">
        <div v-if="plan?.max_seats" class="subscription-stat">
          <p class="subscription-stat-label">{{ $t('pages.office.subscription.recap.seats.label') }}</p>
          <p class="subscription-stat-value">
            {{ $t('pages.office.subscription.recap.seats.value', { value: `${subscription.seats_taken}/${plan.max_seats}` }) }}
          </p>
        </div>
        <div v-if="plan?.max_pages" class="subscription-stat">
          <p class="subscription-stat-label">{{ $t('pages.office.subscription.recap.doc.label') }}</p>
          <p class="subscription-stat-value">
            {{ $t('pages.office.subscription.recap.doc.value', { value: `${subscription.pages_used}/${plan.max_pages}` }) }}
          </p>
        </div>
        <div v-if="subscription?.plan_ends_at" class="subscription-stat">
          <p class="subscription-stat-label">{{ $t('pages.office.subscription.recap.plan_ends_at') }}</p>
          <p class="subscription-stat-value">
            {{ getDisplayDate(subscription.plan_ends_at) }}
          </p>
        </div>
        <div v-else-if="subscription?.trial_ends_at" class="subscription-stat">
          <p class="subscription-stat-label">{{ $t('pages.office.subscription.recap.trial_ends_at') }}</p>
          <p class="subscription-stat-value">
            {{ getDisplayDate(subscription.trial_ends_at) }}
          </p>
        </div>
      </div>

      <template v-if="subscription?.plan_product">
        <div class="subscription-header">
          <h2 class="h2">
            {{ $t("pages.office.subscription.details.title") }}
          </h2>
          <span
            class="subscription-header-message"
            v-html="$t('pages.office.subscription.details.stripe')"
          />
        </div>

        <div class="subscription-box box">
          <div class="subscription-box-left">
            <p>
              <span :class="`type ${subscription.pm_type}`">{{
                subscription.pm_type
              }}</span>
              •••• {{ subscription.pm_last_four }}
            </p>

            <template v-if="getDisplayBilling()">
              <h3>{{ $t("pages.office.subscription.details.subtitle") }}</h3>
              <p v-html="getDisplayBilling()" />
            </template>
          </div>
          <Btn @theme="ghost" @onClick="onModifyPayment">{{
            $t("actions.modify")
          }}</Btn>
        </div>

        <div class="subscription-header">
          <h2 class="h2">
            {{ $t("pages.office.subscription.unsubscribe.title") }}
          </h2>
        </div>

        <div class="subscription-box box">
          <div class="subscription-box-left">
            <p>{{ $t("pages.office.subscription.unsubscribe.text") }}</p>
            <p class="baseline">
              {{ $t("pages.office.subscription.unsubscribe.baseline") }}
            </p>
          </div>
          <Btn theme="alert" @onClick="onUnsubscribe">{{
            $t("actions.unsubscribe")
          }}</Btn>
        </div>
      </template>
    </template>

    <Popup
      v-if="unsubscribe"
      :title="$t('pages.office.subscription.unsubscribe.popup.title')"
      @onClose="onClose"
    >
      <p v-html="$t('pages.office.subscription.unsubscribe.popup.text')" />
    </Popup>
  </div>
</template>

<script>
import Btn from "@/components/elements/Btn";
import Loader from "@/components/elements/Loader";
import Header from "@/components/modules/Header";
import Popup from "@/components/partials/Popup";
import { getDisplayDate } from "@/helpers";
import countries from "@/data/static/countries.json";

export default {
  components: {
    Btn,
    Loader,
    Header,
    Popup,
  },
  beforeMount() {
    this.$store.dispatch("client/getClientSubscription");
  },
  data() {
    return {
      getDisplayDate,
      unsubscribe: false,
    };
  },
  computed: {
    client() {
      const { originalData } = this.$store.state.client;
      return originalData;
    },
    loading() {
      return this.$store.state.client.requests.subscription !== 0;
    },
    subscription() {
      const { subscription } = this.$store.state.client;
      return subscription;
    },
    plan() {
      const plans = this.$store.state.subscription.options;
      if (!plans.length || !this.subscription?.plan_product) return null;
      const plan = plans.find((p) => p.id === this.subscription.plan_product);
      return plan;
    },
    actions() {
      return [
        {
          label: this.subscription?.plan_product
            ? this.$t("actions.upgrade-plan")
            : this.$t("actions.subscribe"),
          action: () => {
            this.$router.push({ name: "Subscription" });
          },
        },
      ];
    },
  },
  methods: {
    getDisplayBilling() {
      const address = this.subscription?.billing_address;
      if (!address) return null;
      const elements = [];
      if (address.line1) elements.push(address.line1);
      if (address.city || address.postal_code)
        elements.push(`${address.postal_code} ${address.city}`.trim());
      if (address.state) elements.push(address.state);
      if (address.country) {
        const iso = address.country.toLowerCase();
        const country = countries.find((c) => c.iso === iso);
        if (country) elements.push(country[this.$i18n.locale]);
      }
      if (this.subscription.vat)
        elements.push(
          `${this.$t("pages.office.subscription.details.tva")} ${
            this.subscription.vat
          }`
        );

      return elements.join("<br>");
    },
    onUnsubscribe(e) {
      this.unsubscribe = true;
    },
    onClose() {
      this.unsubscribe = false;
    },
    onModifyPayment() {
      this.$store.commit("subscription/update", {
        prop: "onlyUpdatePayment",
        value: true,
      });
      this.$router.push({ name: "Subscription" });
    },
  },
};
</script>


<style scoped lang="scss">
.subscription {
  // .header {
  //   :deep(.header-left) {
  //     @include mq($from: "m", $until: "l-big") {
  //       width: auto;
  //     }
  //   }
  // }

  .following {
    margin-top: 40px;
    font-size: rem(32px);
    text-align: center;
    font-weight: 700;

    @include mq($until: "m") {
      font-size: rem(24px);
    }
  }

  &-stat {
    display: flex;

    @include mq($until: "m") {
      flex-direction: column;
      align-items: flex-start;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &-label {
      @include mq($from: "m") {
        margin-right: 16px;
        width: calc(50% - 16px);
        max-width: 264px;
      }
    }

    &-value {
      font-weight: bold;
    }
  }

  &-header {
    @include margin(20px null 4px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mq($until: "m") {
      flex-direction: column;
      align-items: flex-start;
    }

    &-message {
      opacity: 0.6;

      :deep(strong) {
        font-weight: bold;
      }
    }
  }

  &-box {
    display: flex;
    align-items: flex-start;

    @include mq($until: "m") {
      flex-direction: column;
    }

    h3 {
      margin-top: 40px;
      font-weight: bold;
    }

    .button {
      margin-left: auto;

      @include mq($until: "m") {
        @include margin(12px null null 0);
        width: 100%;
      }
    }

    .baseline {
      opacity: 0.6;
      font-size: rem(12px);
    }

    .type {
      margin-right: 8px;
      text-transform: capitalize;

      &.visa {
        text-transform: uppercase;
      }
    }
  }

  :deep(.popup-wrapper) {
    max-width: 544px;
  }
}
</style>