import router from '../router';
import { calls, authCalls } from '@/services/calls';
import { dispatchNotif } from '@/services/notifications';
import { deepCopy } from '@/helpers';

const user = {
  namespaced: true,
  state: {
    requests: {
      detail: 0,
      picture: 0,
      categories: 0,
      auth: 0,
      authPage: 0
    },
    profilePicture: null,
    data: {
      name: null,
      role: null,
      categories: []
    },
    originalData: {},
    auth: {
      isAuth: false,
      requests: 0,
      appRequests: 0
    },
    categoriesSearch: '',
    categories: []
  },
  mutations: {
    update(state, { prop, value }) {
      state[prop] = value;
    },
    updateDataProp(state, { prop, value }) {
      state.data[prop] = value;
    },
    updateOriginalDataProp(state, { prop, value }) {
      state.data[prop] = value;
    },
    addUserCategory(state, item) {
      state.data.categories.push(item);
    },
    removeUserCategory(state, item) {
      const index = state.data.categories.map(c => c.id).indexOf(item.id);
      if(index >= 0) {
        state.data.categories.splice(index, 1);
      }
    },
    updateAuth(state, { prop, value }) {
      state.auth[prop] = value;
    },
    increaseRequests(state, type) {
      state.requests[type]++;
    },
    decreaseRequests(state, type) {
      state.requests[type]--;
    }
  },
  actions: {
    reset({ commit }) {
      commit('update', { prop: 'profilePicture', value: null });
      commit('update', { prop: 'data', value: { name: null, role: null, categories: [] } });
      commit('update', { prop: 'auth', value: { name: null, role: null, categories: [] } });
      commit('update', { prop: 'originalData', value: {} });
      commit('update', { prop: 'categoriesSearch', value: '' });
      commit('update', { prop: 'categories', value: [] });
      commit('update', { props: 'requests', value: {
        detail: 0,
        picture: 0,
        categories: 0,
        auth: 0
      }});
    },
    async authenticate({ state, commit, dispatch }, { email, password, redirect, requestType }) {
      if (!requestType) requestType = 'auth';
      commit('increaseRequests', requestType);
      try {
        const preRequest = await authCalls.get('sanctum');
        const request = await authCalls.post('login', { email, password });

        if (request.status === 'success') {
          dispatch('getUser', { redirect });
          commit('updateAuth', { prop: 'isAuth', value: true });
        }

        commit('decreaseRequests', requestType);
        return request;
      } catch (error) {
        commit('decreaseRequests', requestType);
        console.log('error :', error);
        return error;
      }
    },
    async forgotPassword({ commit }, { email }) {
      commit('increaseRequests', 'authPage');
      const preRequest = await authCalls.get('sanctum');
      const request = await authCalls.post('forgotPassword', { email });
      commit('decreaseRequests', 'authPage');
      return request;
    },
    async resetPassword({ commit }, params) {
      commit('increaseRequests', 'authPage');
      const preRequest = await authCalls.get('/sanctum/csrf-cookie');
      const request = await authCalls.post('resetPassword', params);
      commit('decreaseRequests', 'authPage');
      return request;
    },
    async authCheck({ state, commit, dispatch }) {
      const { cookie } = document;
      const { pathname } = window.location;

      if (cookie && cookie.split(';').some((item) => item.trim().startsWith('XSRF-TOKEN='))) {
        const redirect = pathname === '/' || pathname.match(/forgot|register|reset/g);
        const user = await dispatch('getUser', { redirect, getSubscription: true });

        if (user.status === 'success') {
          commit('updateAuth', { prop: 'isAuth', value: true });

          // Check to display welcome screen
          // if (user.data?.onboarding?.has_set_a_client_name === false && user.data.admin !== 1) {
          //   router.push({ name: 'Welcome' });
          // }
          return;
        }
      }

      if (pathname !== '/' && !pathname.match(/forgot|register|reset|subscribe|forgot/g)) {
        router.push({ name: 'Login', query: { 'redirect': encodeURIComponent(pathname) } });
      }
    },
    async getUser({ state, commit, dispatch }, params) {
      const redirect = params?.redirect ? params.redirect : false;
      const requestType = params?.requestType || 'auth';
      commit('increaseRequests', requestType);

      const request = await calls.get('getUser');
      const { data } = request;

      if (request.status === 'success') {
        // Check to get onboarding status
        const userStatus = await calls.get('getOnboardingStatus');

        if (userStatus.status === 'success') {
          data.onboarding = { ...userStatus.data };
        }

        const profile = {
          categories: [],
          ...data,
          role: data.admin === 1 ? 'admin' : 'user'
        };

        // Profile
        commit('update', { prop: 'data', value: deepCopy(profile) });
        commit('update', { prop: 'originalData', value: deepCopy(profile) });
        // Client
        commit('client/updateUuid', data.client.uuid, { root: true });
        commit('client/update', { prop: 'data', value: deepCopy(data.client) }, { root: true });
        commit('client/update', { prop: 'originalData', value: deepCopy(data.client) }, { root: true });

        if (redirect) {
          if (typeof redirect === 'string') router.push({ path: redirect });
          else router.push({ name: state.data.role === 'admin' ? 'Admin' : 'LegalCases' });
        }

        if (params.getSubscription) {
          await dispatch('client/getClientSubscription', null, { root: true });
        }
      }

      commit('decreaseRequests', requestType);
      return { status: request.status, data };
    },
    async getUserOnboardingStatus({ commit }) {
      const request = await calls.get('getOnboardingStatus');
      if (request.status === 'success') {
        commit('updateDataProp', { prop: 'onboarding', value: deepCopy(request.data) });
        commit('updateOriginalDataProp', { prop: 'onboarding', value: deepCopy(request.data) });
      }

      return request;
    },
    async logout({ commit, dispatch }) {
      const request = await authCalls.post('logout');
      commit('updateAuth', { prop: 'isAuth', value: false });
      router.push('/');
      setTimeout(() => {
        window.location.reload();
      }, 100);
      // dispatch('reset', null, { root: true });
    },
    async checkInvitation({ state, commit }, params) {
      const request = await calls.post('checkInvitation', params);
      // const request = await checkInvitation(params);
      return request;
    },
    async register({ state, commit, dispatch }, { params, requestType }) {
      if (!requestType) requestType = 'auth';
      commit('increaseRequests', requestType);
      const preRequest = await authCalls.get('sanctum');
      const request = await authCalls.post('register', params);
      commit('decreaseRequests', requestType);
      return request;
    },
    async setClientName({ state }, name) {
      const request = await calls.post('setClientName', { name });
      return request;
    },
    async getUserCategories({ state, commit }) {
      commit('increaseRequests', 'categories');
      const request = await calls.get('getUserCategories');

      if (request.status === 'success') {
        const categories = [];
        const { data } = request;
        for (const property in data) {
          categories.push(data[property]);
        }

        categories.sort((a, b) => {
          if (a.description < b.description) return -1;
          else if (a.description > b.description) return 1;
          return 0;
        });

        commit('updateDataProp', { prop: 'categories', value: categories });
      }

      commit('decreaseRequests', 'categories');
      return request;
    },
    async getAllCategories({ state, commit }) {
      commit('increaseRequests', 'categories');
      const request = await calls.get('getCategories', {
        params: { 'number-per-page': 100 },
      });
      // const request = await getAllCategories();

      if (request.status === 'success') {
        const { data } = request;
        data.sort((a, b) => {
          if (a.description < b.description) return -1;
          else if (a.description > b.description) return 1;
          return 0;
        });
        // const { data } = request.data.data;
        commit('update', { prop: 'categories', value: data });
      }
      commit('decreaseRequests', 'categories');
    },
    async setUserCategories({ state, commit, dispatch }) {
      commit('increaseRequests', 'categories');
      const { categories } = state.data;
      const ids = categories.map(c => c.id);
      const request = await calls.post('setUserCategories', { category_ids: ids });

      if (request.status === 'success') {
        dispatchNotif({ type: 'success', text: 'Vos matières ont été mises à jour' });
      }

      commit('decreaseRequests', 'categories');
      return request;
    },
    async updateUser({ state, commit, dispatch }) {
      const { originalData } = state;
      const { lawyer_firstname, lawyer_lastname, email } = state.data;

      if (
        lawyer_firstname === originalData.lawyer_firstname &&
        lawyer_lastname === originalData.lawyer_lastname &&
        email === originalData.email
      ) return;

      commit('increaseRequests', 'detail');

      const request = await calls.post('updateUser', {
        firstname: lawyer_firstname,
        lastname: lawyer_lastname,
        email
      });

      if (request.status === 'success') {
        dispatch('getUser', { requestType: 'detail' });
        dispatchNotif({ type: 'success', text: 'Données enregistrées' });
      }

      commit('decreaseRequests', 'detail');
    },
    async updatePicture({ state, commit }) {
      const { profilePicture } = state;
      if (!profilePicture) return;
      commit('increaseRequests', 'picture');

      const formData = new FormData();
      formData.append('picture', profilePicture);
      const request = await calls.post('updateUserPicture', formData);

      commit('decreaseRequests', 'picture');
    },
    async updateOnboarding({ state, commit }, type = 'done') {
      if (!type) return;

      try {
        let request = null;
        switch (type) {
          case 'casus':
            request = await calls.post('setOnboardingCasus');
            break;
        
          default:
            request = await calls.post('setOnboardingDone');
            break;
        }
      } catch (error) {
        console.log('error :', error);
        dispatchNotif({ type: 'error', text: error });
      }
    }
  }
}

export default user;
