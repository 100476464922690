<template>
  <div class="page payment">
    <Header
      :title="$t(`pages.subscription.${step}.title`)"
      :text="text"
      sticky
    />

    <div class="payment-view">
      <div v-if="loading" :class="`payment-view-loader ${step}`">
        <Loader/>
      </div>

      <!-- Plans -->
      <div v-show="step === 'plans'" class="payment-plans">
        <Plan
          v-for="p in plans"
          :key="`plan-${p.id}`"
          :plan="p"
          :isCurrent="p.id === currentSubscription?.plan_product"
          :isDisabled="p.max_seats && p.max_seats < currentSubscription.seats_taken"
          @onClick="onOption"
        />
      </div>

      <!-- Stripe (card & billing) -->
      <form
        v-show="step.match(/^payment|billing$/)"
        id="payment-form"
        class="payment-form box"
        novalidate
        @submit.prevent="onStripeSubmit"
      >
        <div class="payment-form-wrapper">
          <div :class="`payment-form-elements invoice${step === 'payment' ? ' is-visible' : ''}${paymentReady ? ' is-ready' : ''}`">
            <input
              v-model="invoice"
              id="payment-invoice"
              type="checkbox"
              class="u-visually-hidden"
            />
            <label for="payment-invoice">
              <svg viewBox="0 0 16 16" fill="currentColor" focusable="false" role="presentation">
                <path d="M14.25 8C14.25 11.4518 11.4518 14.25 8 14.25C4.54822 14.25 1.75 11.4518 1.75 8C1.75 4.54822 4.54822 1.75 8 1.75C11.4518 1.75 14.25 4.54822 14.25 8Z" stroke="currentColor" stroke-width="1.5" fill="none"></path>
                <circle v-if="invoice" cx="8" cy="8" r="3.5" />
              </svg>
              Facture
            </label>
          </div>
          <div id="payment-elements" :class="`payment-form-elements${step === 'payment' ? ' is-visible' : ''}`"/>
          <div id="billing-elements" :class="`payment-form-elements${step === 'billing' ? ' is-visible' : ''}`"/>
          <div :class="`payment-form-elements vat${step === 'billing' ? ' is-visible' : ''}${addressReady ? ' is-ready' : ''}`">
            <label>Numéro de TVA</label>
            <input :value="vat" @input="onVat"/>
          </div>
        </div>
        <Btn :disabled="disabledNext" type="submit">{{ $t("actions.continue") }}</Btn>
      </form>

      <!-- Recap -->
      <div v-if="step === 'recap'" class="payment-recap">
        <div v-if="plan" class="payment-recap-box box">
          <div class="payment-recap-left">
            <p v-if="plan.title">{{ plan.title }}</p>
            <p v-if="plan.description">{{ plan.description }}</p>
            <!-- <p v-if="plan">{{ $t(`plans.${plan.shortcode}.title`) }}</p> -->
            <!-- <p v-if="plan">{{ $t(`plans.${plan.shortcode}.size`) }}</p> -->
          </div>
          <div class="payment-recap-right">
            <p v-if="plan.price">
              <strong>{{ $t('plans.price', { price: plan.price }) }}</strong>
            </p>
          </div>
        </div>
        <div class="payment-recap-box box">
          <div class="payment-recap-left">
            <p>{{ $t(`pages.subscription.payment.title`) }}</p>
          </div>
          <div class="payment-recap-right">
            <p>
              <strong>{{ $te(`pages.subscription.recap.methods.${recap.payment}`) ? $t(`pages.subscription.recap.methods.${recap.payment}`) : recap.payment }}</strong>
            </p>
          </div>
        </div>
        <div class="payment-recap-box box">
          <div class="payment-recap-left">
            <p>{{ $t(`pages.subscription.billing.title`) }}</p>
          </div>
          <div v-if="recap.address" class="payment-recap-right">
            <p><strong>{{ recap.name }}</strong></p>
            <p><strong>{{ recap.address.line1 }}</strong></p>
            <p v-if="recap.address.line2"><strong>{{ recap.address.line2 }}</strong></p>
            <p><strong>{{ recap.address.postal_code }}, {{ recap.address.city }}</strong></p>
            <p><strong>{{ vat }}</strong></p>
          </div>
        </div>
        <Btn @onClick="onSubscribe">{{ $t('actions.subscribe') }}</Btn>
      </div>
    </div>

    <Popup
      v-if="updatePlanPopup"
      :title="$t('plans.update.title')"
      @onClose="updatePlanPopup = false"
    >
      <div class="popup-content">
        <p class="popup-text" v-html="$t('plans.update.text', { current: currentPlan.name, next: plan.name })"/>
        <div class="popup-actions">
          <Btn @click="updatePlanPopup = false">{{ $t('actions.cancel') }}</Btn>
          <Btn theme="alert" @onClick="onChangePlan">{{ $t('actions.confirm') }}</Btn>
        </div>
        <div v-if="subscriptionState.requests.changePlan !== 0" class="popup-loader">
          <Loader />
        </div>
      </div>
    </Popup>
  </div>
</template>


<script>
import Btn from "@/components/elements/Btn";
import Plan from "@/components/modules/Plan";
import Input from "@/components/elements/Input";
import Header from "@/components/modules/Header";
import Loader from "@/components/elements/Loader";
import Popup from "@/components/partials/Popup";
import { loadStripe } from '@stripe/stripe-js';
import { checkVAT, belgium, france, germany, netherlands, luxembourg } from 'jsvat';

export default {
  components: {
    Btn,
    Plan,
    Input,
    Popup,
    Header,
    Loader,
  },
  beforeMount() {
    if (this.onlyUpdatePayment) {
      this.step = 'payment';
    }
  },
  async mounted() {
    this.getData();
  },
  beforeUnmount() {
    this.$store.commit('subscription/update', { prop: 'onlyUpdatePlan', value: false });
    this.$store.commit('subscription/update', { prop: 'onlyUpdatePayment', value: false });
  },
  data() {
    return {
      updatePlanPopup: false,
      step: 'plans', // plans, payment, billing, recap

      stripe: null,
      elements: null,
      paymentElement: null,
      addressElement: null,
      loadingStripe: false,
      invoice: false,
      vat: null,

      paymentComplete: false,
      paymentReady: false,
      addressComplete: false,
      addressReady: false,

      errors: {},
      loadingRecap: false,
      loadingSubscription: false,
      recap: {
        name: null,
        payment: 'card',
        address: null
      }
    };
  },
  computed: {
    loading() {
      const { options } = this.$store.state.subscription.requests;
      return this.loadingStripe || this.loadingRecap || options !== 0 || this.loadingSubscription;
    },
    subscriptionState() {
      return this.$store.state.subscription;
    },
    plan() {
      const { price_id } = this.$store.state.subscription.form;
      const plan = this.plans.find(p => p.price_id === price_id);
      return plan;
    },
    plans() {
      const { options } = this.$store.state.subscription;
      return options;
    },
    text() {
      return this.step === 'plans' ? this.$t('pages.subscription.plans.text') : null;
    },
    disabledNext() {
      if (this.step === 'payment') {
        if (this.invoice) return false;
        return !this.paymentComplete;
      }
      if (this.step === 'billing') {
        // const checkVat = checkVAT(this.vat, [belgium, france, germany, netherlands, luxembourg]);
        return !this.addressComplete || !this.vat?.length;
        // return !this.addressComplete || !checkVat.isValid;
      }
      return false;
    },
    client() {
      const { originalData } = this.$store.state.client;
      return originalData;
    },
    currentPlan() {
      if (!this.currentSubscription) return;
      const { plan_product } = this.currentSubscription;
      const plan = this.plans.find(p => p.id === plan_product);
      return plan;
    },
    currentSubscription() {
      const { subscription } = this.$store.state.client;
      return subscription;
    },
    onlyUpdatePayment() {
      return this.$store.state.subscription.onlyUpdatePayment;
    } 
  },
  watch: {
    step() {
      this.getData();
    },
    invoice(val) {
      if (val) {
        this.paymentElement.collapse();
        this.recap.payment = 'invoice';
      }
    }
  },
  methods: {
    getData() {
      switch (this.step) {
        case 'plans':
          const { options } = this.$store.state.subscription.requests;
          if (!this.plans?.length && options === 0) this.$store.dispatch('subscription/getOptions');
          break;
        case 'payment':
          this.initStripe();
          break;
        case 'billing':
          this.initBilling();
          break;
        case 'recap':
          this.initRecap();
          break;
      }
    },
    async initStripe() {
      if ((this.stripe && this.elements) || this.loadingStripe) return;  
      this.loadingStripe = true;
      await this.$store.dispatch('subscription/getIntent');
      this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
      const { intent } = this.$store.state.subscription;
      this.elements = this.stripe.elements({ clientSecret: intent.client_secret });
      this.initPayment();
      this.loadingStripe = false;
    },
    initPayment() {
      if (this.paymentElement) return;
      const options = {
        layout: {
          type: 'accordion',
          defaultCollapsed: true
        },
      };

      this.paymentElement = this.elements.create('payment', options);
      this.paymentElement.mount("#payment-elements");
      this.paymentElement.on('ready', this.onPaymentReady.bind(this));
      this.paymentElement.on('change', this.onPaymentChange.bind(this));
    },
    initBilling() {
      if (this.addressElement) return;
      this.addressElement = this.elements.create('address', { mode: 'billing' });
      this.addressElement.on('ready', this.onAddressReady.bind(this));
      this.addressElement.on('change', this.onAddressChange.bind(this));
      this.addressElement.mount("#billing-elements");
    },
    async initRecap() {
      if (!this.addressElement) return;
      this.loadingRecap = true;
      const req = await this.addressElement.getValue();
      const { address, name } = req.value;
      this.recap.name = name;
      this.recap.address = address;
      this.loadingRecap = false;
    },
    onVat(e) {
      this.vat = e.target.value;
    },
    onOption(plan) {
      this.$store.commit('subscription/updateForm', { prop: 'price_id', value: plan.price_id });

      if (plan.is_custom_plan) {
        window.location.href('mailto:info@symplicy.com');
        return;
      }

      // If already subscribed
      if (this.currentSubscription.plan_product) {
        this.updatePlanPopup = true;
        return;
      }

      // Go to following step
      this.step = 'payment';
    },
    async onChangePlan() {
      await this.$store.dispatch('subscription/updatePlan');
      this.updatePlanPopup = false;
    },
    onStripeSubmit() {
      if (this.disabledNext) return;
      if (this.$store.state.subscription.onlyUpdatePayment) {
        this.updatePayment();
        return;
      }

      this.step = this.step === 'payment' ? 'billing' : 'recap';
    },
    onPaymentChange(e) {
      const { value, complete, collapsed } = e;
      const { type } = value;
      if (!collapsed) {
        this.recap.payment = type;
        if(this.invoice) this.invoice = false;
      }
      
      this.paymentComplete = complete;
    },
    onPaymentReady(e) {
      this.paymentReady = true;
    },
    onAddressReady(e) {
      this.addressReady = true;
    },
    onAddressChange(e) {
      const { complete } = e;
      this.addressComplete = complete;
    },
    onSubscribe() {
      // if (this.subscriptionState.onlyUpdatePayment) {
      //   this.update
      // }
      this.proceedPayment();
    },
    async proceedPayment() {
      this.loadingSubscription = true;
      if (this.invoice) {
        this.$store.commit('subscription/updateForm', { prop: 'address', value: this.recap.address });
        this.$store.commit('subscription/updateForm', { prop: 'name', value: this.recap.name });
        this.$store.commit('subscription/updateForm', { prop: 'vat', value: this.vat });
        await this.$store.dispatch('subscription/subscribe', { invoice: this.invoice });
      } else {
        const { setupIntent, error } = await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: {
            return_url: `${window.location.origin}/subscription/checkout`,
          },
          redirect: 'if_required'
        });

        if (error) {
          if (error.type === "card_error" || error.type === "validation_error") console.log(error.message);
          else console.log("An unexpected error occured.");
        } else {
          this.$store.commit('subscription/updateForm', { prop: 'payment_method_id', value: setupIntent.payment_method });
          this.$store.commit('subscription/updateForm', { prop: 'address', value: this.recap.address });
          this.$store.commit('subscription/updateForm', { prop: 'name', value: this.recap.name });
          this.$store.commit('subscription/updateForm', { prop: 'vat', value: this.vat });
          await this.$store.dispatch('subscription/subscribe', { invoice: this.invoice });
        }
      }
      this.loadingSubscription = false;
    },
    async updatePayment() {
      this.loadingSubscription = true;
      if (this.invoice) {
        await this.$store.dispatch('subscription/updatePayment', { invoice: true });
      } else {
        const { setupIntent, error } = await this.stripe.confirmSetup({
          elements: this.elements,
          confirmParams: {
            return_url: `${window.location.origin}/subscription/checkout`,
          },
          redirect: 'if_required'
        });

        if (error) {
          if (error.type === "card_error" || error.type === "validation_error") console.log(error.message);
          else console.log("An unexpected error occured.");
        } else {
          this.$store.commit('subscription/updateForm', { prop: 'payment_method_id', value: setupIntent.payment_method });
          await this.$store.dispatch('subscription/updatePayment', { invoice: false });
        }
      }
      this.loadingSubscription = false;
    },
  },
};
</script>


<style lang="scss" scoped>
.payment {
  &-view {
    position: relative;

    &-loader {
      &.payment,
      &.billing,
      &.recap {
        @include position(absolute, 0 null null 0);
        @include size(100%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        background-color: rgba(255, 255, 255, .5);
      }

      &.recap {
        ~ .payment-recap {
          opacity: .5;
        }
      }
    }
  }

  // Plans
  &-plans {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr;

    @include mq($from: 'm') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mq($from: 'l-big') {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-choice {
    display: flex;
    .button {
      + .button {
        margin-left: 16px;
      }
    }
  }

  // Card & Address
  &-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100px;

    &-wrapper {
      width: 100%;
      max-width: 496px;
    }

    &-elements {
      width: 100%;

      &:not(.is-visible) {
        display: none;
      }

      // Invoice Input
      &.invoice {
        &:not(.is-ready) {
          opacity: 0;
        }

        input {
          &:checked {
            + label {
              color: rgb(5, 112, 222);
            }
          }
        }

        label {
          @include padding(16px);
          display: flex;
          position: relative;
          z-index: 1;
          border: 1px solid rgb(230, 230, 230);
          border-bottom: 0;
          border-radius: 5px 5px 0 0;
          color: rgb(109, 110, 120);
          font-weight: 600;
          line-height: 1;
          transition: color .2s ease-out;
          cursor: pointer;

          svg {
            margin-right: 55px;
            width: 16.8px;
            height: 16.8px;
          }

          &:hover,
          &:focus {
            color: rgb(48, 49, 61);
          }

          &::after {
            @include position(absolute, 100% null null 0);
            @include size(calc(100% + 2px), 5px);
            content: '';
            left: -1px;
            border: 1px solid rgb(230, 230, 230);
            border-bottom: 0;
            background-color: white;
            pointer-events: none;
          }
        }
      }

      // VAT Input
      &.vat {
        transition: opacity .6s linear;

        &:not(.is-ready) {
          opacity: 0;
        }

        label {
          @include margin(12px null 4px);
          display: block;
          font-size: 14.88px;
          transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        }

        input {
          display: block;
          width: 100%;
          padding: 12px;
          background-color: var(--white);
          border-radius: 5px;
          transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
          border: 1px solid #e6e6e6;
          box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
          font-size: rem(16px);
          color: rgb(48, 49, 61);

          &:focus {
            outline: 0px;
            border-color: rgba(5, 115, 225, 0.5);
            box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 1px 0px, rgba(0, 0, 0, 0.02) 0px 3px 6px 0px, rgba(5, 115, 225, 0.25) 0px 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 1px 1px 0px;
          }
        }
      }
    }

    .button {
      @include margin(32px null null auto);

      @include mq($until: 'm') {
        width: 100%;
        margin-top: 20px;
      }
    }
  }

  &-recap {
    &-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      @include mq($until: 'm') {
        flex-direction: column;
      }
    }

    &-left {
      flex-grow: auto;
    }

    &-right {
      flex-shrink: 0;

      p {
        text-align: right;
      }
    }

    .button {
      display: block;
      margin-left: auto;

      @include mq($until: 'm') {
        width: 100%;
      }
    }
  }

  .popup {
    &-actions {
      display: grid;
      justify-items: stretch;
      grid-gap: 16px;
      margin-top: 16px;

      @include mq($from: "m") {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &-loader {
      @include position(absolute, 0 null null 0);
      @include size(100%);
      display: flex;
      align-items: center;
      z-index: 1;
      background-color: rgba($white, .7);
    }
  }
}
</style>