<template>
  <div class="layout-view paywall">
    <div class="box page">
      <h1>{{ $t('paywall.title') }}</h1>
      <p>{{ $t('paywall.text') }}</p>
      <Btn :to="{ name: 'Subscription' }">{{ $t('paywall.button') }}</Btn>
    </div>
  </div>
</template>


<script>
import Btn from '@/components/elements/Btn';

export default {
  components: {
    Btn
  }
};
</script>


<style lang="scss" scoped>
.page {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    font-size: rem(12px);
    opacity: .6;
  }

  .button {
    margin-top: 16px;
  }
}
</style>