<template>
  <div :class="`plan box${isCurrent ? ' is-current' : ''}`">
    <h2 class="plan-title">{{ plan.title }}</h2>
    <p class="plan-subtitle">
      <Sprite v-if="plan.most_popular" type="auth-check"/>
      <span class="plan-subtitle-text">{{ plan.sub_title }}</span>
    </p>
    <p class="plan-price">
      {{ plan.is_custom_plan ? plan.contact_sentence : $t('plans.price', { price: plan.price }) }}
    </p>
    <Btn
      :theme="!plan.most_popular ? 'ghost' : ''"
      @onClick="$emit('onClick', plan)"
      :disabled="isCurrent || isDisabled"
    >
      {{ getButtonLabel() }}
    </Btn>
    <p v-if="plan.features_header" class="plan-more">{{ plan.features_header }}</p>

    <ul class="plan-advantages">
      <template v-for="feature in plan.features" :key="`feature-${feature}`">
        <li>
          <Sprite type="check"/>
          {{ feature }}
        </li>
      </template>
    </ul>

    <!-- <h2 class="plan-title">{{ $t(`plans.${plan.shortcode}.title`) }}</h2> -->
    <!-- <span class="plan-subtitle-text">{{ $t(`plans.${plan.shortcode}.subtitle`) }}</span> -->
    <!-- <p class="plan-price">{{ $t(`plans.${plan.shortcode}.price`) }}</p> -->
    <!-- <Btn @onClick="$emit('onClick', plan)" :theme="!highlighted ? 'ghost' : ''">{{ $t(`actions.${contact ? 'contact_us' : 'continue'}`) }}</Btn> -->
    <!-- <p v-if="more" class="plan-more">{{ more }}</p> -->
    <!-- <ul class="plan-advantages">
      <template v-for="number in 10" :key="`advantage-${plan.shortcode}-${number}`">
        <li v-if="$te(`plans.${plan.shortcode}.advantages.${number}`)">
          <Sprite type="check"/>
          {{ $t(`plans.${plan.shortcode}.advantages.${number}`) }}
        </li>
      </template>
    </ul> -->
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Sprite from '@/components/elements/Sprite';

export default {
  components: {
    Btn,
    Sprite
  },
  props: {
    plan: {
      type: Object,
      required: true
    },
    isCurrent: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  // mounted() {
  //   console.log('plan :', this.plan);
  // }
  methods: {
    getButtonLabel() {
      if (this.isCurrent) return this.$t('plans.current');
      // if (this.isDisabled) return this.$t('plans.current');
      if (this.plan.is_custom_plan && this.plan.contact_sentence) return this.$t('actions.contact_us');
      return this.$t('actions.continue');
    }
  }
}
</script>

<style lang="scss" scoped>
.plan {
  padding-bottom: 40px;

  &.is-current {
    border: 2px solid var(--primary);
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: (48 / 32);

    @include mq($from: 'm') {
      font-size: rem(32px);
    }
  }

  &-subtitle {
    display: flex;
    align-items: center;
    font-size: rem(12px);
    line-height: 2;

    .svg {
      margin-right: 8px;
    }

    &-text {
      opacity: .6;
      min-height: 24px;
    }
  }

  &-price {
    margin-top: 8px;
    font-size: rem(16px);
    line-height: (24 / 16);

    @include mq($from: 'm') {
      min-height: 48px;
    }
  }

  .button {
    @include margin(16px null 24px);
    display: block;
    width: 100%;

    @include mq($from: 'l-big') {
      margin-bottom: 32px;
    }
  }

  &-current {
    @include margin(16px null 24px);
    min-height: 34px;

     @include mq($from: 'l-big') {
      margin-bottom: 32px;
    }
  }

  &-more {
    font-weight: 700;
    margin-bottom: 12px;
  }

  &-advantages {
    li {
      display: flex;

      + li {
        margin-top: 6px;

        @include mq($from: 'l-big') {
          margin-top: 16px;
        }
      }

      .svg {
        @include margin(4px 8px null null);
        flex-shrink: 0;
        fill: var(--symplicy);
      }
    }
  }
}
</style>