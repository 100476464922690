<template>
  <nav
    ref="container"
    :class="[
      'legalcase-menu',
      { 'is-visible': isVisible }
    ]"
  >
    <ul class="legalcase-menu-list">
      <li
        v-for="link in links"
        :key="`legalcase-menu-item-${link.name}`"
        :class="[
          'legalcase-menu-item',
          { 'legalcase-menu-item--active': link.name === $route.name }
        ]"
      >
        <router-link
          class="legalcase-menu-link"
          :to="{
            name: link.name,
            params: $route.params
          }"
        >
          {{ $t(`pages.${link.name.toLowerCase()}.title`) }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { isDescendant } from '@/helpers';

export default {
  data() {
    return {
      // eslint-disable-next-line
      _documentClick: this.onDocumentClick.bind(this),
    }
  },
  computed: {
    links() {
      const routes = this.$router.getRoutes();
      const route = routes.filter(r => r.name === 'LegalCase')[0];
      const legalcase = this.$store.state.legalCase.data;
      const filtereds = route.children.filter(r => {
        if (!r.name) return false;
        if (!r.meta || !r.meta.if) return true;
        return r.meta.if && r.meta.if.condition(legalcase);
      });

      return filtereds;
    },
    isVisible() {
      const { legalcaseMenu } = this.$store.state.responsive.visibilities;
      return legalcaseMenu;
    },
  },
  watch: {
    $route() {
      this.onClose();
    },
    isVisible(val) {
      if (window.innerWidth >= 768) return;
      if (val) {
        // Not launching directly the listener to avoid the trigger on the toggle
        setTimeout(() => {
          document.addEventListener('click', this._documentClick);
        }, 100);
        return;
      }

      document.removeEventListener('click', this._documentClick);
    }
  },
  methods: {
    onClose() {
      this.$store.commit('responsive/toggleVisibility', 'legalcaseMenu');
    },
    onDocumentClick(e) {
      const { target } = e;
      const descendant = isDescendant(this.$refs.container, target);
      if (!descendant && this.isVisible) this.onClose();
    }
  }
}
</script>

<style lang="scss" scoped>
// Menu
.legalcase-menu {
  @include position(absolute, 0 0 null null);
  @include size(320px, 100%);
  @include padding(62px 16px);
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-left: 1px solid var(--grey-border);
  overflow: auto;
  z-index: 24;

  @include mq($until: 'm') {
    transition: opacity .3s $ease-out-quart, transform .3s $ease-out-quart;

    &:not(.is-visible) {
      transform: translateX(10px);
      opacity: 0;
      pointer-events: none;
    }
  }

  @include mq($from: "m") {
    display: none;
  }

  &-list {
    display: flex;
    flex-direction: column;
  }

  &-item {
    width: 100%;
  }

  &-link {
    @include padding(11px 8px 13px);
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: 4px;
    line-height: (16 / 14);
    text-decoration: none;
    color: var(--black-light);
    transition: color .2s $ease-out-quart, background-color .2s $ease-out-quart;

    &:hover,
    &.router-link-active {
      font-weight: bold;
    }

    &.router-link-active {
      background-color: var(--white-dark);
      font-weight: bold;
    }
  }
}
</style>