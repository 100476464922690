<template>
  <button v-if="forceDisplay || display?.match(/officeMenu|accountMenu/)" :class="`burger${open ? ' is-open' : ''}`" @click="$emit('onClick')">
    <span v-for="line in 4" :key="`burger-line-${line}`" :class="`burger-line line-${line}`"/>
  </button>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false
    },
    forceDisplay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    display() {
      const { meta } = this.$route;
      return meta.sidebarLeft;
    }
  }
}
</script>

<style lang="scss" scoped>
.burger {
  @include position(fixed, null 16px 16px null);
  @include size(32px);
  border-radius: 10px;
  background-color: var(--white-dark);
  transition: background-color .3s $ease-out-quart;
  z-index: 12;

  @include mq($from: 'l') {
    display: none;
  }

  &.is-open {
    .burger-line {
      &.line-1 {
        opacity: 0;
        transform: translateY(6px);
        transition: transform .2s $ease-out-quart, opacity .2s .1s $ease-out-quart;
      }

      &.line-2 {
        transform: rotate(45deg);
        transition: transform .2s .15s $ease-out-quart;
      }

      &.line-3 {
        transform: rotate(-45deg);
        transition: transform .2s .15s $ease-out-quart;
      }

      &.line-4 {
        opacity: 0;
        transform: translateY(-6px);
        transition: transform .2s $ease-out-quart, opacity .2s .1s $ease-out-quart;
      }
    }
  }

  &-line {
    @include size(calc(100% - 14px), 2px);
    @include position(absolute, calc(50% - 1px) null null 7px);
    background-color: var(--grey);

    &.line-1 {
      margin-top: -6px;
      transition: transform .2s .1s $ease-out-quart, opacity .2s .1s $ease-out-quart;
    }

    &.line-2,
    &.line-3 {
      transition: transform .2s $ease-out-quart;
    }

    &.line-4 {
      margin-top: 6px;
      transition: transform .2s .1s $ease-out-quart, opacity .2s .1s $ease-out-quart;
    }
  }
}
</style>