<template>
  <div class="auth">
    <img
      class="auth-logo"
      src="/img/logo.svg"
    />
    <div class="auth-wrapper">
      <template v-if="!success">
        <h1 class="auth-title">{{ locales.title }}</h1>
        <p v-if="locales.text" class="auth-text">{{ locales.text }}</p>

        <form
          class="auth-form"
          novalidate
          @submit.prevent="onSubmit"
        >
          <Field
            v-for="field in displayFields"
            :id="fields[field].id"
            :key="fields[field].id"
            :type="fields[field].type"
            :reveal="fields[field].reveal"
            :value="fields[field].value"
            :error="fields[field].error"
            :label="fields[field].label"
            :checked="fields[field].checked"
            @input.self="onInput($event, field)"
            @reveal="fields[field].reveal ? onReveal(field) :null"
          />

          <Btn type="submit" :title="locales.submit" :disabled="disabled">
            {{ locales.submit }}
          </Btn>

          <p v-if="error" class="auth-error">{{ error }}</p>
        </form>

        <router-link
          v-if="locales.link"
          :to="locales.link.to"
          class="auth-link"
        >
          {{ locales.link.label }}
        </router-link>

        <p v-if="locales.subline" class="auth-subline">
          {{ locales.subline.text }}
          <router-link
            v-if="locales.subline.link"
            :to="locales.subline.link.to"
            class="auth-link"
          >
            {{ locales.subline.link.label }}
          </router-link>
        </p>

        <div v-if="loader" class="auth-loader">
          <Loader />
        </div>
      </template>
      <template v-else>
        <h1 v-if="locales.success?.title" class="auth-title success">{{ locales.success.title }}</h1>
        <p v-if="locales.success?.text" class="auth-text">{{ locales.success.text }}</p>
        <router-link
          v-if="locales.success?.link"
          :to="locales.success.link.to"
          class="auth-link"
        >
          {{ locales.success.link.label }}
        </router-link>
      </template>
    </div>
    <Points v-if="!$route.name?.match(/Forgot|Reset/)"/>
    <Footer />
  </div>
</template>

<script>
import Btn from '@/components/elements/Btn';
import Loader from '@/components/elements/Loader';
import Footer from '@/components/partials/Footer';
import Field from '@/components/modules/authentication/Field';
import Points from '@/components/modules/authentication/Points';

export default {
  name: 'Authentication',
  components: {
    Btn,
    Loader,
    Footer,
    Field,
    Points
  },
  beforeMount() {
    const { name } = this.$route;
    if (name?.match(/Register|Reset/)) this.getParams();
  },
  data() {
    return {
      token: null,
      client: null,
      error: null,
      hasError: false,
      success: false,
      error422: this.$t('authentication.login.422'),
      fields: {
        firstname: {
          id: 'firstname',
          type: 'text',
          value: '',
          error: null,
          reveal: false,
          label: this.$t('labels.firstname'),
        },
        lastname: {
          id: 'lastname',
          type: 'text',
          value: '',
          error: null,
          reveal: false,
          label: this.$t('labels.lastname'),
        },
        email: {
          id: 'email',
          type: 'email',
          value: '',
          error: null,
          reveal: false,
          label: this.$t('labels.email'),
        },
        password: {
          id: 'password',
          type: 'password',
          value: '',
          error: null,
          reveal: true,
          label: this.$t('labels.password'),
        },
        confirm: {
          id: 'password-confirmation',
          type: 'password',
          value: '',
          error: null,
          reveal: true,
          label: this.$t('labels.passwordConfirm'),
        },
        cgu: {
          id: 'cgu',
          type: 'checkbox',
          value: 'cgu',
          checked: false,
          error: null,
          label: this.$t('labels.checkbox-cgu'),
        },
        privacy: {
          id: 'privacy',
          type: 'checkbox',
          value: 'privacy',
          checked: false,
          error: null,
          label: this.$t('labels.checkbox-privacy'),
        },
      }
    }
  },
  computed: {
    loader() {
      const requests = this.$store.state.user.requests.authPage;
      return requests !== 0;
    },
    displayFields() {
      switch (this.$route.name) {
        case 'Forgot':
          return ['email'];
        case 'Reset':
          return ['password', 'confirm'];
        case 'Register':
          return ['lastname', 'email', 'password', 'confirm'];
        case 'Subscribe':
          return ['firstname', 'lastname', 'email', 'password', 'cgu', 'privacy'];
        default:
          return ['email', 'password'];
      }
    },
    locales() {
      switch (this.$route.name) {
        case 'Forgot':
          return {
            title: this.$t('authentication.forgot.title'),
            text: this.$t('authentication.forgot.text'),
            submit: this.$t('authentication.forgot.submit'),
            link: {
              label: this.$t('authentication.forgot.login'),
              to: { name: "Login" }
            },
            success: {
              title: this.$t('authentication.forgot.success'),
              link: {
                label: this.$t('authentication.forgot.login'),
                to: { name: "Login" }
              }
            }
          };
        case 'Reset':
          return {
            title: this.$t('authentication.reset.title'),
            submit: this.$t('authentication.reset.submit'),
            success: {
              title: this.$t('authentication.reset.success'),
              link: {
                label: this.$t('authentication.reset.login'),
                to: { name: "Login" }
              }
            }
          };
        case 'Register':
          return {
            title: this.$t('authentication.register.title'),
            text: this.client ? this.client : null,
            submit: this.$t('authentication.register.submit'),
          };
        case 'Subscribe':
          return {
            title: this.$t('authentication.subscribe.title'),
            submit: this.$t('authentication.subscribe.submit'),
            subline: {
              text: this.$t('authentication.subscribe.subline.text'),
              link: {
                label: this.$t('authentication.subscribe.subline.link'),
                to: { name: "Login" }
              }
            }
          };
        default:
          return {
            title: this.$t('authentication.login.title'),
            submit: this.$t('authentication.login.submit'),
            link: {
              label: this.$t('authentication.login.forgot'),
              to: { name: "Forgot" }
            },
            subline: {
              text: this.$t('authentication.login.subline.text'),
              link: {
                label: this.$t('authentication.login.subline.link'),
                // to: { name: "Welcome" }
                to: { name: "Subscribe" }
              }
            }
          };
      }
    },
    disabled() {
      if (this.displayFields.includes('cgu') || this.displayFields.includes('privacy')) {
        const { cgu, privacy } = this.fields;
        return !cgu.checked || !privacy.checked;
      }

      return false;
    }
  },
  watch: {
    $route(current, oldVal) {
      this.success = false;
      this.resetErrors();

      if (current && (current.name === 'Register' || current.name === 'Reset')) {
        this.getParams();
      } else {
        this.client = false;
        this.token = false;
      }
    }
  },
  methods: {
    getParams() {
      const { query } = this.$route;

      if (query) {
        const { email, token, client, name } = query;
        this.fields.lastname.value = name; // To check
        this.fields.email.value = email;
        this.token = token;
        this.client = client;
      }
    },
    validate(fields) {
      if (!fields) fields = [...this.displayFields];
      this.resetErrors(fields);

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const el = this.fields[field];

        if (el.type === "checkbox") {
          if (!el.checked) {
            el.error = this.$t(`errors.checkbox-${el.id}`)
          }
        } else if (!el.value || !el.value.length) {
          el.error = this.$t('errors.required');
        } else {
          // Email
          if (field === 'email') {
            // eslint-disable-next-line
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!regex.test(this.fields[field].value)) {
              el.error = this.$t('errors.email');
            }
          }

          // Password
          if (field === 'password') {
            if (this.$route.name === 'Register' || this.$route.name === 'Subscribe') {
              if (el.value.length < 8) {
                el.error = this.$t('errors.passwordLength');
              }
            }
          }

          // Confirmation
          if (field === 'passwordConfirm') {
            if (this.fields.password.value && this.fields.password.value !== el.value) {
              el.error = this.$t('errors.passwordConfirm');
            }
          }
        }
      }

      for (let i = 0; i < fields.length; i++) {
        if (this.fields[fields[i]].error) this.hasError = true;
      }
    },
    resetErrors(fields) {
      if (!fields) fields = [...this.displayFields];
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        this.fields[field].error = null;
      }

      this.error = null;
      this.hasError = false;
    },

    // Events
    onInput(e, field) {
      const f = this.fields[field];
      if (f.type === 'checkbox') {
        f.checked = e;
        return;
      }
      f.value = e;
    },
    onReveal(field) {
      this.fields[field].type = this.fields[field].type === 'password' ? 'text' : 'password';
    },
    onSubmit() {
      this.validate();
      if (this.hasError) return;

      switch (this.$route.name) {
        case 'Forgot':
          this.onForgot();
          break;
        case 'Register':
          this.onRegister();
          break;
        case 'Subscribe':
          this.onSubscribe();
          break;
        case 'Reset':
          this.onReset();
          break;
        default:
          this.onLogin();
          break;
      }
    },

    async onForgot() {
      const { email } = this.fields;
      const request = await this.$store.dispatch('user/forgotPassword', { email: email.value });
      this.success = request.status === 'success';
    },

    async onReset() {
       const { token } = this;
       const { email, password, confirm } = this.fields;

      const request = await this.$store.dispatch('user/resetPassword', {
        email: email.value,
        password: password.value,
        password_confirmation: confirm.value,
        token: token
      });

      this.success = request.status === 'success';
    },

    // With invitation
    async onRegister() {
      const { token } = this;
      const { lastname, email, password, confirm } = this.fields;

      const auth = await this.$store.dispatch('user/register', {
        requestType: 'authPage',
        params: {
          name: lastname.value,
          email: email.value,
          password: password.value,
          password_confirmation: confirm.value,
          token: token
        }
      });

      if (auth.status === 'error') {
        if (!auth.data) return;

        if (auth.data.code === 'already_used_token') {
          this.error = this.$t('authentication.register.already_used_token');
          return;
        }

        if (auth.data.code === 'invalid_token') {
          this.error = this.$t('authentication.register.invalid_token');
          return;
        }

        if (auth.data.status === 422) {
          this.error = auth.data.data.message;
          return;
        }

        this.error = this.$t('errors.500');
        return;
      }

      this.$store.commit('user/updateAuth', { prop: 'isAuth', value: true });
      this.$store.dispatch('user/getUser');
      this.$router.push({ name: 'LegalCases' });
    },

    // Without invitation
    async onSubscribe() {
      const { firstname, lastname, email, password } = this.fields;

      const auth = await this.$store.dispatch('user/register', {
        requestType: 'authPage',
        params: {
          firstname: firstname.value,
          lastname: lastname.value,
          email: email.value,
          password: password.value,
          password_confirmation: password.value,
        }
      });

      if (auth.status === 'error') {
        if (!auth.data) return;

        if (auth.data?.response?.status === 422) {
          const message = auth.data.response?.data?.message;
          if (message) {
            if (message === "The email has already been taken.") {
              this.error = this.$t('errors.email-taken');
              return;
            }

            this.error = message;
          }
        }
        
        this.error = this.$t('errors.500');
        return;
      }

      this.$store.commit('user/updateAuth', { prop: 'isAuth', value: true });
      this.$store.dispatch('user/getUser');
      this.$router.push({ name: 'Welcome' });
    },

    async onLogin() {
      this.validate();
      if (this.hasError) return;
      const { email, password } = this.fields;
      const redirect = this.$route.query.redirect || true;
      const auth = await this.$store.dispatch('user/authenticate', { email: email.value, password: password.value, redirect, requestType: 'authPage' });

      if (auth.status === 'error') {
        if (auth?.data?.response?.status === 422) {
          this.error = this.$t('authentication.login.422');
        }
      }
    },
  }
}
</script>


<style lang="scss" scoped>
.auth {
  @include size(100%);
  @include margin(null auto);
  @include padding(null 16px 40px);
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-width: 1172px;

  @include mq($until: 'm') {
    @include padding(56px null);
    flex-direction: column;
    overflow: auto;
    justify-content: flex-start;
  }

  &-logo {
    margin-bottom: 40px;

    @include mq($from: 'm') {
      @include position(absolute, 16px 16px);
      z-index: 1;
      margin-bottom: 0;
    }
  }

  &-wrapper {
    @include padding(40px 16px);
    @include margin(null auto);
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 450px;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 8px 80px rgba(0, 0, 0, 0.16);

    @include mq($from: 'm') {
      @include padding(null 32px);
      width: 50%;
      max-width: 578px;
    }

    @include mq($from: 'l') {
      @include padding(56px);
    }
  }

  &-loader {
    @include position(absolute, 0 null null 0);
    @include size(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    border-radius: 8px;
    background-color: rgba($white, .7);
  }

  &-title {
    font-size: 24px;
    font-weight: 700;
    line-height: (32 / 24);

    &.success {
      font-size: rem(18px);
      text-align: center;
    }

    + .auth-text {
      margin-top: 8px;
    }
  }

  &-text {
    color: var(--grey-darker);
  }

  &-form {
    margin-top: 32px;

    .field {
      + .field {
        margin-top: 24px;
      }

      &.field--checkbox {
        + .field--checkbox {
          margin-top: 12px;
        }
      }
    }

    .button {
      @include padding(16px null);
      display: block;
      width: 100%;
      margin-top: 32px;
    }

    .auth-error {
      margin-top: 8px;
      color: var(--alert);
      font-weight: 600;
    }
  }

  &-link {
    margin-top: 16px;
    align-self: center;
    color: var(--black-light);
    text-decoration: none;
    font-weight: 700;;
  }

  &-subline {
    margin-top: 32px;
    text-align: center;

    a {
      // color: var(--primary);
      text-decoration: underline;
      white-space: nowrap;
    }
  }

  .points {
    width: 100%;
    max-width: 450px;

    @include mq($until: 'm') {
      margin-top: 40px;
    }

    @include mq($from: 'm') {
      width: 50%;
      max-width: 477px;
      margin-right: 32px;
    }

    + .auth-wrapper {
      @include margin(null unset);
    }
  }

  .footer {
    width: 100%;

    @include mq($from: "m") {
      @include position(absolute, null null 10px 0);
    }
  }
}
</style>