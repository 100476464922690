<template>
  <div class="page documents">
    <!-- <div class="container"> -->
      <Header
        :title="$t(`pages.${page.name.toLowerCase()}.title`)"
        :mobileToggle="true"
        @onToggle="onSearchToggle"
      />

      <template v-if="!loading">
        <template v-if="!isSearch">
          <!-- Pendings -->
          <List
            v-if="pendings && pendings.length && page"
            :title="$t('lists.documents.unprocesseds')"
            :items="pendings"
            :selected="selected"
            @onClick="onClickDoc"
          />

          <!-- Processeds -->
          <List
            v-if="processeds && processeds.length && page"
            :title="$t('lists.documents.processeds')"
            :items="processeds"
            :selected="selected"
            @onClick="onClickDoc"
          />

          <!-- Faileds -->
          <List
            v-if="faileds && faileds.length && page"
            :title="$t('lists.documents.faileds')"
            :items="faileds"
            :selected="selected"
            @onClick="onClickDoc"
          />

          <Pagination
            v-if="pendings.length || processeds.length || faileds.length"
            :total="total"
            :current="parseInt($route?.query?.page, 10) || 1"
            :perPage="globals.pagination.perPage"
          />

          <p
            v-if="!pendings.length && !processeds.length && !faileds.length"
            class="noresult"
          >
            {{ $t('documents.noresult.default') }}
          </p>
        </template>
        <template v-else>
          <List
            :title="$t('lists.documents.search', { nbr: search.length })"
            :items="search"
            :selected="selected"
            @onClick="onClickDoc"
          />

          <p
            v-if="!search.length"
            class="noresult"
          >
            {{ $t('documents.noresult.default') }}
          </p>
        </template>
      </template>

      <Loader v-else/>
    <!-- </div> -->
  </div>
</template>


<script>
import _ from 'lodash';
import Header from '@/components/modules/Header';
import Loader from '@/components/elements/Loader';
import List from '@/components/modules/List';
import Pagination from '@/components/modules/Pagination';

import globals from '@/data/static/globals.json';

export default {
  name: 'JuridicalDocuments',
  components: {
    Header,
    List,
    Loader,
    Pagination
  },
  async beforeMount() {
    this.$store.commit('documents/updateScopes', ['others']);
    this.$store.commit('polysearch/updateName', 'juridical-documents');
    await this.$store.dispatch('polysearch/getFilters');

    const { query } = this.$route;
    const filters = Object.entries(query);

    if (filters.length) {
      filters.map(filter => {
        this.$store.commit('polysearch/updateValue', { field: filter[0], value: filter[1] });
      });
      this.$store.dispatch('documents/search', { status: "processed" });
      return;
    }

    this.getData();
  },
  data() {
    return {
      globals,
      // debounceGetData: _.debounce(this.search.bind(this), 2000, { 'leading': false, })
    }
  },
  computed: {
    page() {
      return this.$route;
    },
    pendings() {
      return this.$store.state.documents.items.filter(d => d.state === 'pending' || d.state === 'success'); // processed
    },
    processeds() {
      return this.$store.state.documents.items.filter(d => d.state === 'processed');
    },
    faileds() {
      return this.$store.state.documents.items.filter(d => d.state === 'failure');
    },
    isSearch() {
      return this.$store.state.documents.isSearch;
    },
    search() {
      return this.$store.state.documents.search;
    },
    selected() {
      return this.$store.state.preview.document;
    },
    loading() {
      return this.$store.state.documents.requests !== 0
    },
    total() {
      return this.$store.state.documents.total;
    }
  },
  watch: {
    $route(newRoute, oldRoute) {
      if (newRoute.name === 'JuridicalDocuments') {
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      const { page } = this.$route.query;

      if (page) {
        this.$store.dispatch('documents/getDocuments', { offset: (page - 1) * globals.pagination.perPage, status: "processed" });
        return;
      }

      this.$store.dispatch('documents/getDocuments', { status: "processed" });
    },
    onClickDoc(doc) {
      this.$store.dispatch('preview/set', doc);
    },
    onSearchToggle() {
      this.$store.commit('responsive/toggleVisibility', 'search');
    }
  }
};
</script>