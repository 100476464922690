<template>
  <div
    :class="[
      'layout',
      { 'layout--detail': $route?.meta?.detail },
      { 'layout--staging': isStaging },
    ]"
  >
    <Topbar />

    <div
      :class="{ 
        'layout-main': true,
        'layout-main--sidebar-left': hasLeftSidebar && !hasRightSidebar,
        'layout-main--sidebar-right': !hasLeftSidebar && hasRightSidebar,
        'layout-main--sidebar-both': hasLeftSidebar && hasRightSidebar
      }"
    >
      <PayWall v-if="paywall"/>
      <template v-else>
        <Sidebar-left/>

        <div class="layout-view">
          <router-view/>
        </div>

        <Sidebar-right/>
        <LegaleCaseMenu v-if="topbar === 'legalcase'"/>
      </template>
    </div>

    <StagingBar v-if="isStaging"/>
    <Upload :multiple="multipleUpload"/>
    <Notifications/>
  </div>
</template>

<script>
import BurgerButton from '@/components/elements/BurgerButton';
import Topbar from '@/components/partials/Topbar';
import Footer from '@/components/partials/Footer';
import PayWall from '@/components/partials/PayWall';
import Upload from '@/components/partials/Popups/Upload';
import StagingBar from '@/components/partials/StagingBar';
import SidebarLeft from '@/components/partials/SidebarLeft';
import SidebarRight from '@/components/partials/SidebarRight';
import Notifications from '@/components/partials/Notifications';
import LegaleCaseMenu from '@/components/partials/LegaleCaseMenu';

export default {
  components: {
    BurgerButton,
    Topbar,
    Footer,
    Upload,
    PayWall,
    StagingBar,
    SidebarLeft,
    SidebarRight,
    Notifications,
    LegaleCaseMenu
  },
  props: {
    isStaging: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    hasLeftSidebar() {
      if (this.paywall) return false;
      const { meta } = this.$route;
      return meta.sidebarLeft;
    },
    hasRightSidebar() {
      if (this.paywall) return false;
      const { sidebarRight } = this.$route.meta;
      switch (sidebarRight) {
        case 'preview':
          return this.$store.state.preview.document ? sidebarRight : null;
        case 'lawyer':
          return this.$store.state.lawyer.id ? sidebarRight : null;
        case 'lawyers':
          return sidebarRight;
        default: 
          return null;
      }
    },
    uploadVisible() {
      return this.$store.state.upload.visible;
    },
    multipleUpload() {
      const role = this.$store.state.user?.data?.role;
      return role === null || role !== 'admin';
    },
    topbar() {
      const { meta } = this.$route;
      return meta?.topbar;
    },
    paywall() {
      const { subscription } = this.$store.state.client;
      const { $route } = this;
      return subscription && subscription.can_access === false && $route.name !== "Subscription";
    }
  },
  // methods: {
  //   onBurger() {
  //     this.$store.commit('responsive/toggleVisibility', 'menu');
  //   }
  // }
};
</script>


<style lang="scss">
.layout {
  @include size(100%, 100vh);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto minmax(0, 1fr) auto;
  grid-template-areas:
    "topbar"
    "view"
  ;
  overflow: hidden;
  background-color: var(--white-dark);
  color: var(--black-light);

  @include mq($until: 'm') {
    grid-template-rows: minmax(0, 1fr);
    grid-template-areas: "view";
  }

  &.layout--staging {
    grid-template-rows: auto minmax(0, 1fr) 30px;
    grid-template-areas:
      "topbar"
      "view"
      "stagingbar"
    ;

    @include mq($until: 'm') {
      grid-template-rows: minmax(0, 1fr) 30px;
      grid-template-areas:
        "view"
        "stagingbar"
      ;
    }
  }

  .topbar {
    grid-area: topbar;

    @include mq($until: 'm') {
      @include position(fixed, 0 null null 0);
      z-index: 100;
      width: 100%;
    }
  }

  &-main {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;

    @include mq($from: 'l') {
      &--sidebar-left {
        grid-template-columns: 320px 1fr;

        .layout-view {
          justify-content: flex-start;
        }
      }

      &--sidebar-right {
        grid-template-columns: 1fr 320px;

        .layout-view {
          justify-content: flex-end;
        }
      }

      &--sidebar-both {
        grid-template-columns: 320px 1fr 320px;
      }
    }
  }

  &-view {
    // @include padding(32px 16px 78px);
    @include padding(78px 16px);
    @include margin(null auto);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: auto;
    position: relative;
    width: 100%;
    height: 100%;

    @include mq($from: 'l') {
      @include padding(32px 24px);
      // height: calc(100vh - 56px);
    }
  }

  .page {
    width: 100%;
    max-width: $gs-container;

    &-actions {
      display: flex;
      justify-content: flex-end;
    }
  }

  // Sidebars
  .sidebar {
    @include size(320px, 100%);
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    border-left: 1px solid var(--grey-border);
    overflow: auto;

    @include mq($until: 'm') {
      @include padding(46px null);
    }

    @include mq($until: 'l') {
      @include position(absolute, 0 0 null null);
      height: calc(100% - 44px);
      z-index: 24;
      
      &--full {
        height: 100%;
      }
    }

    @include mq($from: 'l') {
      overflow: auto;

      &.sidebar-left {
        @include padding(16px);
        border-right: 1px solid var(--grey-border);
        border-left: 0;
      }
    }

    // &-wrapper {
    //   @include size(100%);
    // }

    &-header {
      @include position(sticky, 0 null null 0);
      // @include position(absolute, 0 null null 0);
      @include padding(8px 16px);
      display: flex;
      justify-content: flex-end;
      flex-shrink: 0;
      z-index: 4;
      width: 100%;
      background-color: rgba($white, .4);
      backdrop-filter: blur(12px);

      @include mq($from: 'l') {
        display: none;
      }
    }

    .button.icon.sidebar-close {
      background-color: var(--white-dark);
    }

    &-content {
      @include mq($until: 'l') {
        @include padding(16px);
        flex-grow: 1;
      }
    }
  }
}
</style>
